import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import psl_actions from "../../libs/psl_actions.js";

import {wait} from "../../libs/psl_polyfills.js";

import './style.scss';
import template from './page.pst';

const PAGE_UID='page_20';
const activity = 2;

import state from "../../state.js";

import audio from "../../components/audio/component.js";

psl_ui.templates.add(PAGE_UID,template);

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._action=async e=>{
			audio.play("bonus-earned-in-video-game-2058");
			if(!this._rendered.rpoints.checkbox.classList.contains('hidden')) return;
			
			state.activities[activity]=true;
			this._rendered.rpoints.checkbox.classList.remove('hidden');
			this._rendered.rpoints.arrow.classList.add('hidden');
			
			this._rendered.rpoints.popup.classList.add('hidden');
			await wait(500);
			this._rendered.rpoints.continue.classList.remove('hidden');
		}
		
		this._reinit();
	}
	
	_reinit(){
		this._rendered.rpoints.checkbox.classList.add('hidden');
		this._rendered.rpoints.arrow.classList.remove('hidden');
		
		this._rendered.rpoints.popup.classList.remove('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');
	}
	
	async show(){
		await super.show();
		
		psl_actions.add(`${PAGE_UID}.click`,this._action);
	}
	async push(){
		await super.show();
	}
	async hide(){
		await super.hide();
		
		psl_actions.remove(`${PAGE_UID}.click`,this._action);
		
		await this._reinit();
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
