import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import psl_actions from "../../libs/psl_actions.js";

import './style.scss';
import template from './page.pst';

import state from "../../state.js";

import CONFIG from '../../../CONFIG.js';

const PAGE_UID='page_31';

psl_ui.templates.add(PAGE_UID,template);

const _get_label_for_score=()=>{
	return new Promise(async (resolve,reject)=>{
		try{
			let response = await fetch('http://5.78.103.21:8090/get_label?score='+state.score);
			let json = await response.json();
			resolve(json.label);
			
// 			const { ipcRenderer } = window.require('electron');
// 			ipcRenderer.once('label_for_score', function (evt, label) {
// 				console.log(label); // Returns: {'SAVED': 'File Saved'}
// 				resolve(label);
// 			});
//			ipcRenderer.send('label_for_score', state.score);
		} catch (e){
			//We're in the web
			resolve('test');
		}
	});
}

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._on_replay=()=>{
			state.reinit();
			psl_router.route(CONFIG.initial_page);
		}
	}
	async show(){
		this.view.classList[CONFIG.links_enabled?"remove":"add"]("links_disabled");
		this._rendered.rpoints.score.innerHTML=state.score;
		this._rendered.rpoints.score_label.innerHTML=await _get_label_for_score();
		await super.show();
		psl_actions.add(`${PAGE_UID}.replay`,this._on_replay);
	}
	async push(){
		this._rendered.rpoints.score.innerHTML=state.score;
		await super.show();
	}
	async hide(){
		await super.hide();
		
		psl_actions.remove(`${PAGE_UID}.replay`,this._on_replay);
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
