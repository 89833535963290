import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import psl_actions from "../../libs/psl_actions.js";

import {wait} from "../../libs/psl_polyfills.js";

import './style.scss';
import template from './page.pst';

import state from "../../state.js";

import audio from "../../components/audio/component.js";

const PAGE_UID='page_23';

psl_ui.templates.add(PAGE_UID,template);

const _width=240;
const _height=200;
const _y_offset=120;

function _generate_particle(type){
	
	let state={
		type,
		img:new Image(),
		status:"idling",
		timer:0,
	};
	
	state.img.src=`/img/${PAGE_UID}/${type}.png`;
	
	state.img.className="species";
	
	state.img.controller=state;
	state.img.dataset.action=`${PAGE_UID}.click`;
	
	return state;
}

function _next(){
	return 2500+1500*Math.random();
}

function _get_adjacent_pos({x,y}){
	try{
		let target=_grid[y-1][x];
		if(!target.type && !target.busy) return {x:x,y:y-1};
	} catch(e){}
	try{
		let target=_grid[y][x+1];
		if(!target.type && !target.busy) return {x:x+1,y:y};
	} catch(e){}
	try{
		let target=_grid[y+1][x];
		if(!target.type && !target.busy) return {x:x,y:y+1};
	} catch(e){}
	try{
		let target=_grid[y][x-1];
		if(!target.type && !target.busy) return {x:x-1,y:y};
	} catch(e){}
	
	//TODO diagonal?
}

let _grid;

function _grid_empty(){
	for(let i=0;i<_grid.length;i++){
		for(let j=0;j<_grid[i].length;j++){
			if(_grid[i][j].type) return false;
		}
	}
	return true;
}

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._state={
			started:false,
			last:Date.now(),
			countdown:20000,
			cta:0,
		}
		
		this._on_click=async e=>{
			if(!this._state.started) return;
			if(this._state.countdown<=0) return;
			let position=psl_ui.DOM.controller(e.target);
			if(!position) return;
			audio.play("game-ball-tap-2073");
			switch(position.status){
				case 'moving':
					position.status='disappearing';
					state.score+=50;
					this._state.cta+=30;
				case 'idling':
					position.status='disappearing';
					state.score+=20;
					this._state.cta+=23;
				break;
			}
		}
		
		this._on_begin=async ()=>{
			this._rendered.rpoints.note.classList.add('hidden');
			this._rendered.rpoints.begin.classList.add('hidden');
			await wait(500);
			this._rendered.rpoints.popup.classList.remove('hidden');
			this._state.started=true;
			
			this._state.last=Date.now();
			window.requestAnimationFrame(()=>this._frame());
		}
		
		this._frame=async ()=>{
			if(!this._state.started) return;
			let d=Date.now()-this._state.last;
			this._state.last=Date.now();
			this._state.countdown-=d;
			
			if(this._state.countdown>0){
				window.requestAnimationFrame(()=>this._frame());
			} else {
				audio.play("unlock-game-notification-253");
				this._state.started=false;
				this._state.countdown=0;
				this._state.cta=159;
				this._rendered.rpoints.popup.classList.add('hidden');
				await wait(500);
				this._rendered.rpoints.continue.classList.remove('hidden');
			}
			
			this._render(d);
		}
		
		this._reinit();
	}
	_reinit(){
		
		this._rendered.rpoints.species.innerHTML='';
		
		_grid=[
			[{},{},{},{},_generate_particle('lizard'),{busy:true},{busy:true},{busy:true}],
			[{},{},{},{},{},{},{},{busy:true}],
			[{},{},_generate_particle('rabbit'),{},_generate_particle('turtle'),_generate_particle('milfoil'),_generate_particle('goldfish1'),_generate_particle('goldfish2')],
			[{},_generate_particle('frog'),{},{},{},{},{},{}],
		];
		
		_grid.forEach((row,i)=>{
			row.forEach((el,j)=>{
				if(!el.img) return;
				el.x=j*_width;
				el.y=_y_offset+i*_height;
				el.grid_x=j;
				el.grid_y=i;
				el.timer=_next();
				this._rendered.rpoints.species.appendChild(el.img);
			});
		});
		
		this._state.started=false;
		this._state.countdown=20000;
		this._state.cta=0;
		
		this._rendered.rpoints.note.classList.remove('hidden');
		this._rendered.rpoints.begin.classList.remove('hidden');
		
		this._rendered.rpoints.popup.classList.add('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');
		
		this._render(0);
	}
	
	async show(){
		await super.show();
		
		psl_actions.add(`${PAGE_UID}.begin`,this._on_begin);
		psl_actions.add(`${PAGE_UID}.click`,this._on_click);
	}
	async push(){
		await super.show();
	}
	async hide(){
		await super.hide();
		
		psl_actions.remove(`${PAGE_UID}.begin`,this._on_begin);
		psl_actions.remove(`${PAGE_UID}.click`,this._on_click);
		
		this._reinit();
	}
	
	_render(d){
		
		
		this._rendered.rpoints.timer.innerHTML=`0:${this._state.countdown<10000?"0":""}${Math.floor(this._state.countdown/1000)}`;
		
		this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
		
		_grid.forEach(row=>{
			row.forEach(el=>{
				if(!el.img) return;
				this._render_particle(el,d);
			})
		});
	}
	
	async _render_particle(particle,d){
		switch(particle.status){
			case "moving":
				particle.scale=1;
				particle.opacity=1;
				
				particle.x=particle.x+(particle.target_x-particle.x)/14;
				particle.y=particle.y+(particle.target_y-particle.y)/14;
					
				if(Math.abs(particle.x-particle.target_x)<1 && Math.abs(particle.y-particle.target_y)<1){
					particle.x=particle.target_x;
					particle.y=particle.target_y;
					
					delete particle.target_x;
					delete particle.target_y;
					particle.status="idling";
				}
			break;
			case "idling":
				particle.scale=1;
				particle.opacity=1;
				particle.timer-=d;
				if(particle.timer<=0){
					let target_pos=_get_adjacent_pos({
						x:particle.x/_width,
						y:(particle.y-_y_offset)/_height
					});
					if(target_pos){
						let new_species=_generate_particle(particle.type);
						new_species.x=particle.x;
						new_species.y=particle.y;
						new_species.target_x=target_pos.x*_width;
						new_species.target_y=target_pos.y*_height+_y_offset;
						
						new_species.grid_x=target_pos.x;
						new_species.grid_y=target_pos.y;
						
						new_species.status="";
						new_species.opacity=1;
						new_species.scale=1;
						this._render_particle(new_species,0);
						new_species.status="moving";
						_grid[target_pos.y][target_pos.x]=new_species;
						new_species.timer=_next();
						particle.timer=_next();
						this._rendered.rpoints.species.appendChild(new_species.img);
					}
				}
			break;
			case "disappearing":
				particle.opacity-=3*d/1000;
				particle.scale+=2*d/1000;
				if(particle.opacity<0){
					particle.img.parentNode.removeChild(particle.img);
					_grid[particle.grid_y][particle.grid_x]={};
					if(_grid_empty()){
						audio.play("unlock-game-notification-253");
						state.score+=100;
						this._state.started=false;
						this._state.countdown=0;
						this._state.cta=159;
						this._render(0);
						this._rendered.rpoints.popup.classList.add('hidden');
						await wait(500);
						this._rendered.rpoints.continue.classList.remove('hidden');
					}
				}
			break;
		}
		
		particle.img.style.transform=`translate3d(${particle.x}px,${particle.y}px,0px) scale3d(${particle.scale},${particle.scale},1)`;
		particle.img.style.opacity=particle.opacity;
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
