import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import psl_actions from "../../libs/psl_actions.js";

import {wait} from "../../libs/psl_polyfills.js";

import state from "../../state.js";

import audio from "../../components/audio/component.js";

import './style.scss';
import template from './page.pst';

import CONFIG from "../../../CONFIG.js";

const PAGE_UID='page_5';

psl_ui.templates.add(PAGE_UID,template);

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));

		this._on_click=async e=>{
			let el=e.target;
			el.classList.add('clicked');
			
			if(el.classList.contains('native')){
				let label=this._rendered.rpoints.native[el.dataset.name];
				label.classList.add('shown');
				setTimeout(()=>{
					el.classList.remove('clicked');
				},1000);
				setTimeout(()=>{
					label.classList.remove('shown');
				},3500);
			} else {
				state.score+=50;
			}
			
			let invasive=Array.from(this.view.querySelectorAll('.species.invasive'));

			if(!this._rendered.rpoints.popup.classList.contains("hidden") && invasive.length === invasive.filter(el=>el.classList.contains('clicked')).length){
				audio.play("unlock-game-notification-253");
				this._rendered.rpoints.popup.classList.add('hidden');
				await wait(500);
				if(CONFIG.page_5_max){
					this._rendered.rpoints.popup2.classList.remove('hidden');
					this._rendered.rpoints.replay.classList.remove('hidden');
				} else {
					this._rendered.rpoints.continue.classList.remove('hidden');
				}
			} else if(el.classList.contains('native')){
				audio.play("click-error-1110");
			} else {
				audio.play("game-ball-tap-2073");
			}
		}
		
		this._reinit();
	}

	_reinit(){
		Array.from(this.view.querySelectorAll('.species')).forEach(el=>el.classList.remove('clicked'));

		this._rendered.rpoints.popup.classList.remove('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');
		this._rendered.rpoints.replay.classList.add('hidden');
		this._rendered.rpoints.popup2.classList.add('hidden');
	}

	async show(){
		await super.show();

		psl_actions.add(`${PAGE_UID}.click`,this._on_click);
	}
	async push(){
		await super.show();
	}
	async hide(){
		await super.hide();

		psl_actions.remove(`${PAGE_UID}.click`,this._on_click);
		
		this._reinit();
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
