import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import {wait} from "../../libs/psl_polyfills.js";

import psl_actions from "../../libs/psl_actions.js";

import './style.scss';
import template from './page.pst';

import state from "../../state.js";

import audio from "../../components/audio/component.js";

const PAGE_UID='page_9';

psl_ui.templates.add(PAGE_UID,template);

const _positions=[
	{
		img:'/img/page_9/babys-breath-halfpx-outline.png',
		x:405,
		y:814,
		angle:334
	},
	{
		img:'/img/page_9/babys-breath-halfpx-outline.png',
		x:503,
		y:184,
		angle:24
	},
	{
		img:'/img/page_9/babys-breath-halfpx-outline.png',
		x:759,
		y:537,
		angle:339
	},
	{
		img:'/img/page_9/babys-breath-halfpx-outline.png',
		x:1147,
		y:498,
		angle:351
	},
	{
		img:'/img/page_9/babys-breath-halfpx-outline.png',
		x:217,
		y:368,
		angle:34
	},
	{
		img:'/img/page_9/babys-breath-halfpx-outline.png',
		x:1672,
		y:541,
		angle:309
	},
	
	{
		img:'/img/page_9/burdock-plant-small.png',
		x:712,
		y:204,
		angle:330
	},
	{
		img:'/img/page_9/burdock-plant-small.png',
		x:201,
		y:512,
		angle:34
	},
	{
		img:'/img/page_9/burdock-plant-small.png',
		x:1358,
		y:396,
		angle:320
	},
	
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:440,
		y:524,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:719,
		y:401,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:421,
		y:359,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:204,
		y:718,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:584,
		y:897,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:592,
		y:701,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:896,
		y:672,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:930,
		y:325,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:916,
		y:911,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:1601,
		y:399,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:1267,
		y:384,
		angle:0
	},
	{
		img:'/img/page_9/burdock-alone-1.png',
		x:1475,
		y:689,
		angle:0
	},
	
	{
		img:'/img/page_9/hawkweed-option4.png',
		x:750,
		y:785,
		angle:298
	},
	{
		img:'/img/page_9/hawkweed-option4.png',
		x:363,
		y:216,
		angle:348
	},
	{
		img:'/img/page_9/hawkweed-option4.png',
		x:356,
		y:655,
		angle:348
	},
	{
		img:'/img/page_9/hawkweed-option4.png',
		x:596,
		y:300,
		angle:326
	},
	{
		img:'/img/page_9/hawkweed-option4.png',
		x:624,
		y:580,
		angle:349
	},
	{
		img:'/img/page_9/hawkweed-option4.png',
		x:1707,
		y:418,
		angle:319
	},
	{
		img:'/img/page_9/hawkweed-option4.png',
		x:1347,
		y:635,
		angle:297
	},
	{
		img:'/img/page_9/hawkweed-option4.png',
		x:1099,
		y:391,
		angle:359
	}
];

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._particles=_positions.map(particle=>{
			particle.el=document.createElement("img");
			particle.el.src=particle.img;
			particle.el.className="species";

			if(particle.angle===0) particle.angle=-30+60*Math.random();
			
			particle.scale=0;
			particle.opacity=0;
			particle.status="hidden";
			
			this._render_particle(particle,0);

			particle.el.dataset.action=`${PAGE_UID}.click`;
			
			particle.el.controller=particle;
			
			this.view.appendChild(particle.el);
			
			return particle;
		});
		
		this._state={
			countdown:20000,
			cta:0,
		}
		
		this._frame=async ()=>{
			if(this._state.stop) return;
			
			let d=Date.now()-this._state.latest;
			this._state.latest=Date.now();
			this._particles.forEach((particle,i)=>{
				this._render_particle(particle,d);
			});
			this._state.countdown-=d;
			
			this._state.next-=d;
			
			
			if(this._state.next<0){
				let free=this._particles.filter(particle=>particle.status==="hidden");
				if(free.length) free[Math.floor(free.length*Math.random())].status="growing";
				this._state.next=0.75*(1+1*Math.random())*1000;
				
				if(this._state.countdown<3500){
					this._state.next=4000;
				}
			}
			
			if(this._state.countdown>0){
				window.requestAnimationFrame(()=>this._frame());
			} else {
				audio.play("unlock-game-notification-253");
				this._state.cta=67;
				this._state.countdown=0;
				this._rendered.rpoints.popup.classList.add('hidden');
				await wait(500);
				this._rendered.rpoints.continue.classList.remove('hidden');
			}
			
			this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
			
			this._rendered.rpoints.timer.innerHTML=`0:${this._state.countdown<10000?"0":""}${Math.floor(this._state.countdown/1000)}`;
		}
		
		this._on_click=async e=>{
			if(this._state.countdown<=0) return;
			let position=psl_ui.DOM.controller(e.target);
			if(!position) return;
			audio.play("game-ball-tap-2073");
			switch(position.status){
				case 'growing':
					position.status='disappearing';
					state.score+=50;
					this._state.cta+=10;
				case 'idling':
					position.status='disappearing';
					state.score+=20;
					this._state.cta+=5;
				break;
			}
		}
		
		this._reinit();
	}
	_reinit(){
		this._particles.forEach(particle=>{
			particle.scale=0;
			particle.opacity=0;
			particle.status="hidden";
			this._render_particle(particle,0);
		});
		this._state.cta=0;
		this._state.countdown=20000;
		this._state.next=0.75*(1+1*Math.random())*1000;
		
		this._rendered.rpoints.popup.classList.remove('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');
		
		this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
	}
	
	async show(){
		await super.show();
		
		psl_actions.add(`${PAGE_UID}.click`,this._on_click);
		
		this._state.stop=false;
		this._state.latest=Date.now();
		this._frame();
	}
	async push(){
		await super.show();
		
		this._state.stop=false;
		this._state.latest=Date.now();
		this._frame();
	}
	async hide(){
		await super.hide();
		
		psl_actions.remove(`${PAGE_UID}.click`,this._on_click);
		this._state.stop=true;
		this._reinit();
	}
	
	_render_particle(particle,d){
		
		switch(particle.status){
			case "hidden":
				particle.opacity=0;
			break;
			case "growing":
				particle.opacity=1;
				particle.scale+=1*d/1000;
				if(particle.scale>1){
					particle.scale=1;
					particle.status="idling";
				}
			break;
			case "idling":
				particle.opacity=1;
			break;
			case "disappearing":
				particle.opacity-=3*d/1000;
				particle.scale+=2*d/1000;
				if(particle.opacity<0){
					particle.scale=0;
					particle.status="hidden";
				}
			break;
		}
		
		particle.el.style.transform=`translate3d(${particle.x}px,${particle.y}px,0px) rotate(${particle.angle}deg) scale3d(${particle.scale},${particle.scale},1)`;
		particle.el.style.opacity=particle.opacity;
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
