import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import psl_actions from "../../libs/psl_actions.js";

import './style.scss';
import template from './page.pst';

import audio from "../../components/audio/component.js";

const PAGE_UID='page_27';

psl_ui.templates.add(PAGE_UID,template);

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._on_click=()=>{
			audio.play("game-ball-tap-2073");
			psl_router.route("/page_28");
		}
	}
	async show(){
		await super.show();
		psl_actions.add(`${PAGE_UID}.click`,this._on_click);
	}
	async push(){
		await super.show();
	}
	async hide(){
		await super.hide();
		psl_actions.remove(`${PAGE_UID}.click`);
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
