import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import {wait} from "../../libs/psl_polyfills.js";

import psl_actions from "../../libs/psl_actions.js";

import './style.scss';
import template from './page.pst';

import state from "../../state.js";

import audio from "../../components/audio/component.js";

const PAGE_UID='page_26';

psl_ui.templates.add(PAGE_UID,template);

const _positions=[
	{
		img:'/img/page_26/mussels-3.png',
		x:1047,
		y:519,
		angle:0
	},
	{
		img:'/img/page_26/mussels-3.png',
		x:976,
		y:643,
		angle:7
	},
	{
		img:'/img/page_26/mussels-3.png',
		x:1234,
		y:487,
		angle:335
	},
	{
		img:'/img/page_26/mussels-3.png',
		x:311,
		y:739,
		angle:55
	},
	{
		img:'/img/page_26/mussels-3.png',
		x:1441,
		y:484,
		angle:0
	},
	
	{
		img:'/img/page_26/crayfish-1.png',
		x:1450,
		y:416,
		angle:0
	},
	{
		img:'/img/page_26/crayfish-1.png',
		x:328,
		y:674,
		angle:0
	},
	
	{
		img:'/img/page_26/crayfish-2.png',
		x:667,
		y:751,
		angle:35
	},
	{
		img:'/img/page_26/crayfish-2.png',
		x:711,
		y:631,
		angle:351
	},
	{
		img:'/img/page_26/crayfish-2.png',
		x:923,
		y:562,
		angle:350
	},
	
	{
		img:'/img/page_26/milfoil-1.png',
		x:510,
		y:680,
		angle:330
	},
	{
		img:'/img/page_26/milfoil-1.png',
		x:843,
		y:569,
		angle:330
	},
	{
		img:'/img/page_26/milfoil-1.png',
		x:84,
		y:739,
		angle:0
	},
	{
		img:'/img/page_26/milfoil-1.png',
		x:426,
		y:730,
		angle:0
	},
	
	{
		img:'/img/page_26/milfoil-2.png',
		x:191,
		y:750,
		angle:0
	},
	{
		img:'/img/page_26/milfoil-2.png',
		x:1281,
		y:552,
		angle:300
	},
	{
		img:'/img/page_26/milfoil-2.png',
		x:596,
		y:646,
		angle:0
	},
	{
		img:'/img/page_26/milfoil-2.png',
		x:852,
		y:719,
		angle:0
	},
	
	{
		img:'/img/page_26/milfoil-3.png',
		x:1355,
		y:449,
		angle:285
	},
	{
		img:'/img/page_26/milfoil-3.png',
		x:1078,
		y:600,
		angle:0
	},
	
	{
		img:'/img/page_26/milfoil-4.png',
		x:1133,
		y:485,
		angle:0
	},
	{
		img:'/img/page_26/milfoil-4.png',
		x:259,
		y:572,
		angle:45
	},
	{
		img:'/img/page_26/milfoil-4.png',
		x:756,
		y:674,
		angle:25
	},
	{
		img:'/img/page_26/milfoil-4.png',
		x:1551,
		y:414,
		angle:0
	},
	
	{
		img:'/img/page_26/mussels-1.png',
		x:174,
		y:642,
		angle:0
	},
	
	{
		img:'/img/page_26/mussels-2.png',
		x:1545,
		y:345,
		angle:340
	},
	{
		img:'/img/page_26/mussels-2.png',
		x:571,
		y:813,
		angle:0
	},
];

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._particles=_positions.map(particle=>{
			particle.el=document.createElement("img");
			particle.el.src=particle.img;
			particle.el.className="species";

			//if(particle.angle===0) particle.angle=-30+60*Math.random();
			
			particle.scale=0;
			particle.opacity=0;
			particle.status="hidden";
			
			this._render_particle(particle,0);

			particle.el.dataset.action=`${PAGE_UID}.click`;
			
			particle.el.controller=particle;
			
			this.view.appendChild(particle.el);
			
			return particle;
		});
		
		this._state={
			countdown:20000,
			cta:0,
		}
		
		this._frame=async ()=>{
			let d=Date.now()-this._state.latest;
			this._state.latest=Date.now();
			this._particles.forEach((particle,i)=>{
				this._render_particle(particle,d);
			});
			this._state.countdown-=d;
			
			this._state.next-=d;
			
			if(this._state.next<0){
				let free=this._particles.filter(particle=>particle.status==="hidden");
				if(free.length) free[Math.floor(free.length*Math.random())].status="growing";
				this._state.next=0.75*(1+1*Math.random())*1000;
				
				if(this._state.countdown<3500){
					console.log("enough");
					this._state.next=4000;
				}
			}
			
			if(this._state.countdown>0){
				window.requestAnimationFrame(()=>this._frame());
			} else {
				audio.play("unlock-game-notification-253");
				this._state.cta=64;
				this._state.countdown=0;
				this._rendered.rpoints.popup.classList.add('hidden');
				await wait(500);
				this._rendered.rpoints.continue.classList.remove('hidden');
			}
			
			this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
			
			this._rendered.rpoints.timer.innerHTML=`0:${this._state.countdown<10000?"0":""}${Math.floor(this._state.countdown/1000)}`;
		}
		
		this._on_click=async e=>{
			if(this._state.countdown<=0) return;
			let position=psl_ui.DOM.controller(e.target);
			if(!position) return;
			audio.play("game-ball-tap-2073");
			switch(position.status){
				case 'growing':
					position.status='disappearing';
					state.score+=50;
					this._state.cta+=10;
				case 'idling':
					position.status='disappearing';
					state.score+=20;
					this._state.cta+=5;
				break;
			}
		}
	}
	async _reinit(){
		this._particles.forEach(particle=>{
			particle.scale=0;
			particle.opacity=0;
			particle.status="hidden";
			this._render_particle(particle,0);
		});
		this._state.countdown=20000;
		this._state.next=0.75*(1+1*Math.random())*1000;
		this._state.latest=Date.now();
		
		this._state.cta=0;
		
		this._rendered.rpoints.popup.classList.remove('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');
			
		this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
		
		await wait(500);
	}
	
	async show(){
		await this._reinit();
		await super.show();
		
		psl_actions.add(`${PAGE_UID}.click`,this._on_click);
		
		this._frame();
	}
	async push(){
		await this._reinit();
		await super.show();
		
		this._frame();
	}
	async hide(){
		await super.hide();
		
		psl_actions.remove(`${PAGE_UID}.click`,this._on_click);
	}
	
	_render_particle(particle,d){
		
		switch(particle.status){
			case "hidden":
				particle.opacity=0;
			break;
			case "growing":
				particle.opacity=1;
				particle.scale+=1*d/1000;
				if(particle.scale>1){
					particle.scale=1;
					particle.status="idling";
				}
			break;
			case "idling":
				particle.opacity=1;
			break;
			case "disappearing":
				particle.opacity-=3*d/1000;
				particle.scale+=2*d/1000;
				if(particle.opacity<0){
					particle.scale=0;
					particle.status="hidden";
				}
			break;
		}
		
		particle.el.style.transform=`translate3d(${particle.x}px,${particle.y}px,0px) rotate(${particle.angle}deg) scale3d(${particle.scale},${particle.scale},1)`;
		particle.el.style.opacity=particle.opacity;
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
