import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';

import template from './template.pst';

import state from "../../state.js";

import CONFIG from "../../../CONFIG.js";

import "./style.scss";

psl_ui.templates.add('score',template);

const {el,rpoints}=psl_ui.templates.render('score');

rpoints.score.innerHTML=state.score;

psl_pages.view.appendChild(el);

state.events.on('score',score=>{
	rpoints.score.innerHTML=score;
});

psl_pages.events.on("show",uid=>{
	el.classList[!CONFIG.score_enabled || uid==='page_1'?"add":'remove']('hidden');
});
