import psl_events from './libs/psl_events.js';

let _events=new psl_events.class();
let _score=0;

let _activity_1=false;
let _activity_2=false;
let _activity_3=false;
let _activity_4=false;
let _activity_5=false;

const state={
	reinit:()=>{
		state.score=0;
		for(let i in state.activities) state.activities[i]=false;
	},
	events:_events,
	get score(){
		return _score;
	},
	set score(s){
		_score=s;
		_events.emit('score',[_score]);
		return _score;
	},
	get progress(){
		let n=0;
		for(let i in state.activities) if(state.activities[i]) n++;
		
		return n;
	},
	activities:{
		get 1(){
			return _activity_1;
		},
		set 1(v){
			_activity_1=!!v;
			_events.emit('progress');
			return _activity_1;
		},
		get 2(){
			return _activity_2;
		},
		set 2(v){
			_activity_2=!!v;
			_events.emit('progress');
			return _activity_2;
		},
		get 3(){
			return _activity_3;
		},
		set 3(v){
			_activity_3=!!v;
			_events.emit('progress');
			return _activity_3;
		},
		get 4(){
			return _activity_4;
		},
		set 4(v){
			_activity_4=!!v;
			_events.emit('progress');
			return _activity_4;
		},
		get 5(){
			return _activity_5;
		},
		set 5(v){
			_activity_5=!!v;
			_events.emit('progress');
			return _activity_5;
		},
	}
}

export default state;
