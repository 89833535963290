import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';

import template from './template.pst';

import state from "../../state.js";

import "./style.scss";

psl_ui.templates.add('badges',template);

const {el,rpoints}=psl_ui.templates.render('badges');

function _render(){
	for(let i=0;i<6;i++) el.classList.remove(`badges${i}`);
	el.classList.add(`badges${state.progress}`);
}

_render();

psl_pages.view.appendChild(el);

state.events.on('progress',_render);

psl_pages.events.on("show",uid=>{
	el.classList[+(uid.substr(5))<6?"add":'remove']('hidden');
});
