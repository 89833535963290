import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import psl_actions from "../../libs/psl_actions.js";

import {wait} from "../../libs/psl_polyfills.js";

import './style.scss';
import template from './page.pst';

import state from "../../state.js";

import audio from "../../components/audio/component.js";

const PAGE_UID='page_17';

psl_ui.templates.add(PAGE_UID,template);

const _figure_pos=[
	{
		y: 198,
		x: 158,
	},
	{
		y: 362,
		x: 167,
	},
	{
		y: 551,
		x: 177,
	},
	{
		y: 702,
		x: 148,
	}
];

let zindex=2;

const _right_pos=[
	{
		x:1091,
		y:488,
		for:new Set([0]),
	},
	{
		x:918,
		y:533,
		for:new Set([1]),
	},
	{
		x:802,
		y:651,
		for:new Set([2]),
	},
	{
		x:1038,
		y:596,
		for:new Set([3]),
	},
];

const area=[
	[453,363],
	[454,752],
	[453,835],
	[771,900],
	[1081,958],
	[1438,1030],
	[1706,915],
	[1904,820],
	[1905,428],
	[1787,332],
	[1659,242],
	[1537,239],
	[1215,242],
	[1146,252],
	[1083,322],
	[1041,341],
	[702,356]
];

function _inside({x,y}) {

	let inside = false;
	for (let i = 0, j = area.length - 1; i < area.length; j = i++) {
			let xi = area[i][0], yi = area[i][1];
			let xj = area[j][0], yj = area[j][1];
			
			let intersect = ((yi > y) != (yj > y))
					&& (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
			if (intersect) inside = !inside;
	}
	
	return inside;
};

psl_actions.add("page_17.continue",()=>{
	if(!_page._rendered.rpoints.popup3.classList.contains("hidden")){
		_page._rendered.rpoints.popup3.classList.add("hidden");
		audio.play("select-click-1109");
		return;
	}
		
	if(_page._figures[2].draggable){
		_page._rendered.rpoints.popup2.classList.remove("hidden");
		audio.play("unlock-game-notification-253");
		_page._figures.forEach(figure=>figure.draggable=false);
		return;
	}
	
	
	audio.play("select-click-1109");
	psl_router.route("/page_19");
	return;
});

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._figures=Array.from(this.view.querySelectorAll('.figure')).map((figure,i)=>{
			let state={
				i:i,
				el:figure,
				draggable:i!==2
			};
			figure.controller=state;
			return state;
		});
		
		this._state={
			status:'idle',
			cta:0,
		};
		
		this._frame=()=>{
			if(this._state.stop) return;
			
			let d=Date.now()-this._state.last;
			this._state.last=Date.now();
			this._figures.forEach(figure=>{
				if(figure.animate){
					figure.pos.x=figure.pos.x+(figure.animate.x-figure.pos.x)/4;
					figure.pos.y=figure.pos.y+(figure.animate.y-figure.pos.y)/4;
					
					if(Math.abs(figure.pos.x-figure.animate.x)<1 && Math.abs(figure.pos.y-figure.animate.y)<1){
						figure.pos.x=figure.animate.x;
						figure.pos.y=figure.animate.y;
						
						delete figure.animate;
					}
				}
			});
			this._render();
			
			if(this._state.status!=="") window.requestAnimationFrame(()=>this._frame());
		}
		
		this._pointer_down=async e=>{
			if(!_page._rendered.rpoints.popup3.classList.contains("hidden")) return;
			
			if(this._state.status!=='idle') return;
			let figure=psl_ui.DOM.controller(e.target);
			if(!figure || figure.constructor!==Object) return;
			
			if(figure.i===2){
				audio.play("click-error-1110");
				figure.el.classList.add('shaking');
				await wait(800);
				figure.el.classList.remove('shaking');
				this._rendered.rpoints.popup3.classList.remove('hidden');
				this._rendered.rpoints.popup.classList.add('hidden');
				await wait(500);
				this._rendered.rpoints.continue.classList.remove('hidden');
				return;
			}
			
			if(!figure.draggable) return;
			
			this._state.current_figure = figure;
			this._state.mouse_pos_start={
				x:e.clientX,
				y:e.clientY
			};
			figure.el.style.zIndex=zindex++;
			figure.el.style.pointerEvents="none";
			this._state.status="dnd";
			this._rendered.rpoints.labels[figure.i].classList.add('hidden');
		}
		
		this._pointer_move=e=>{
			if(this._state.status!=='dnd') return;
			let figure=this._state.current_figure;
			figure.pos.x=figure.initial_pos.x-(this._state.mouse_pos_start.x-e.clientX)/psl_pages.scale;
			figure.pos.y=figure.initial_pos.y-(this._state.mouse_pos_start.y-e.clientY)/psl_pages.scale;
			
			this._render();
		}
		
		this._pointer_up=async e=>{
			if(this._state.status!=='dnd') return;
			
			let figure=this._state.current_figure;
			if(!figure) return;
			
			this._state.current_figure=undefined;
			this._state.status='idle';
			
			let right_pos=_right_pos.find(pos=>{
			
				if(!_inside(figure.pos)) return false;
				if(pos.occupied) return false;
				if(!pos.for.has(figure.i)) return false;
// 				if(figure.pos.x<500) return false;
// 				if(figure.pos.y<475) return false;
// 				if(figure.pos.y<figure.pos.x*(-13/12)+14725/12) return false; //680
				
				return true;
			});
			
			if(right_pos){
				right_pos.occupied=true;
				figure.draggable=false;
				figure.animate={...right_pos};
				if(figure.i!==2){
					state.score+=50;
					this._state.cta+=27;
					audio.play("game-ball-tap-2073");
				}
			} else {
				figure.animate={...figure.initial_pos};
				this._rendered.rpoints.labels[figure.i].classList.remove('hidden');
			}
			
			figure.el.style.pointerEvents="";
			
			this._render();
			
			if(_right_pos.filter(pos=>pos.occupied).length>0){
				this._rendered.rpoints.popup.classList.add('hidden');
				await wait(500);
				this._rendered.rpoints.continue.classList.remove('hidden');
			}
			
		}
		
		this._reinit();
	}
	
	_reinit(){
		this._figures.forEach(figure=>{
			figure.initial_pos={..._figure_pos[figure.i]};
			figure.pos={..._figure_pos[figure.i]};
			figure.draggable=true;
			this._rendered.rpoints.labels[figure.i].classList.remove('hidden');
		});
		_right_pos.forEach(pos=>pos.occupied=false);
		
		this._state.cta=0;
		
		this._rendered.rpoints.popup.classList.remove('hidden');
		this._rendered.rpoints.popup2.classList.add('hidden');
		this._rendered.rpoints.popup3.classList.add('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');
		
		this._render();
	}
	
	async show(){
		this._render();
		await super.show();
		
		this.view.addEventListener('pointerdown',this._pointer_down);
		this.view.addEventListener('pointermove',this._pointer_move);
		this.view.addEventListener('pointerup',this._pointer_up);
		
		this._state.stop=false;
		this._state.status="idle";
		this._state.last=Date.now();
		this._frame();
	}
	async push(){
		this._render();
		await super.show();
	}
	async hide(){
		await super.hide();
		
		this._state.status="";
		
		this.view.removeEventListener('pointerdown',this._pointer_down);
		this.view.removeEventListener('pointermove',this._pointer_move);
		this.view.removeEventListener('pointerup',this._pointer_up);
		
		this._state.stop=true;
		await this._reinit();
	}
	
	_render(){
		this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
		
		this._figures.forEach(figure=>{
			figure.el.style.top=`${figure.pos.y}px`;
			figure.el.style.left=`${figure.pos.x}px`;
		});
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
