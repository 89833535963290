import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import './style.scss';
import template from './page.pst';

import state from "../../state.js";

import audio from "../../components/audio/component.js";

const PAGE_UID='page_1';

psl_ui.templates.add(PAGE_UID,template);

const _speed=-50;

const _width=3840;

//TODO on touch

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._state={
			status:"",
			position:0,
			speed:_speed
		};
		
		this._frame=()=>{
			let d=Date.now()-this._state.last;
			this._state.last=Date.now();
			this._state.position+=this._state.speed*d/1000;
			if(this._state.speed!==_speed){
				this._state.speed+=(_speed-this._state.speed)*d/1000;
			}
			this._render();
			
			if(this._state.status==="animation") window.requestAnimationFrame(()=>this._frame());
		}
		
		this._pointerdown_view=e=>{
			audio.play("select-click-1109");
			psl_router.route("/page_2");
		}
		
		this._pointerdown=e=>{
			e.stopPropagation();
			e.preventDefault();
			this._state.status="user";
			this._state.initial_x=e.clientX;
			this._state.initial_position=this._state.position;
		}
		
		this._pointermove=e=>{
			if(this._state.status!=="user") return;
			e.stopPropagation();
			e.preventDefault();
			this._state.position=this._state.initial_position+(e.clientX-this._state.initial_x)/psl_pages.scale;
			this._render();
			this._state.prev_x=this._state.last_x;
			this._state.last_x=e.clientX;
			this._state.prev_t=this._state.last_t;
			this._state.last_t=Date.now();
		}
		
		this._pointerup=e=>{
			if(this._state.status!=="user") return;
			e.stopPropagation();
			e.preventDefault();
			this._state.position=this._state.initial_position+(e.clientX-this._state.initial_x)/psl_pages.scale;
			this._render();
			
			let dt=Date.now()-this._state.prev_t;
			let dx=e.clientX-this._state.prev_x;
			
			let speed=dx/dt*100;
			
			this._state.speed=speed;

			this._state.status="animation";
			
			this._state.last=Date.now();
			
			window.requestAnimationFrame(()=>this._frame());
		}
	}
	
	_render(){
		if(this._state.position<=-_width) this._state.position+=_width;
		if(this._state.position>=0) this._state.position-=_width;
		this._rendered.rpoints.species.style.transform=`translate3d(${this._state.position}px,0px,0px)`;
	}
	
	async _reinit(){
		this._state.last=Date.now();
		state.reinit();
	}
	
	async show(){
		await this._reinit();
		await super.show();
		
		this._state.status="animation";
		this._state.last=Date.now();
		this._frame();
		
		this._rendered.rpoints.species.addEventListener("pointerdown",this._pointerdown);
		this._rendered.rpoints.species.addEventListener("pointermove",this._pointermove);
		this._rendered.rpoints.species.addEventListener("pointerup",this._pointerup);
		
		this.view.addEventListener("pointerdown",this._pointerdown_view);
		
	}
	async push(){
		await this._reinit();
		await super.show();
	}
	async hide(){
		await super.hide();
		
		this._state.status="";
		
		this._rendered.rpoints.species.removeEventListener("pointerdown",this._pointerdown);
		this._rendered.rpoints.species.removeEventListener("pointermove",this._pointermove);
		this._rendered.rpoints.species.removeEventListener("pointerup",this._pointerup);
		
		this.view.removeEventListener("pointerdown",this._pointerdown_view);
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
