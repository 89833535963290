import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import psl_actions from "../../libs/psl_actions.js";

import state from "../../state.js";

import audio from "../../components/audio/component.js";

import './style.scss';
import template from './page.pst';

const PAGE_UID='page_6';

psl_ui.templates.add(PAGE_UID,template);

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
	}
	async show(){
		this._render();
		await super.show();
	}
	async push(){
		this._render();
		await super.show();
	}
	async hide(){
		await super.hide();
	}
	_render(){
		for(let i in state.activities){
			this.view.querySelector(`.activity_${i}`).src=`/img/page_6/pin_${state.activities[i]?'green':'red'}.png`;
		}
	}
}

psl_actions.add("page_6.click",(e,data)=>{
	if(e.target.src.indexOf('green')!==-1) return;
	audio.play("select-click-1109");
	psl_router.route(data);
});

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
