import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import {wait} from "../../libs/psl_polyfills.js";

import state from "../../state.js";

import audio from "../../components/audio/component.js";

import './style.scss';
import template from './page.pst';

const PAGE_UID='page_14';

psl_ui.templates.add(PAGE_UID,template);

const _figure_pos=[
	{
		y: 314,
		x: 90,
	},
	{
		y: 314,
		x: 298,
	},
	{
		y: 596,
		x: 90,
	},
	{
		y: 596,
		x: 298,
	}
];

let zindex=1;

const _right_pos=[
	{
		x:833,
		y:335,
		dx:245,
		dy:100,
		for:new Set([0,1,2,3]),
	},
	{
		x:1270,
		y:310,
		dx:235,
		dy:100,
		for:new Set([0,1,2,3]),
	},
	{
		x:859,
		y:520,
		dx:255,
		dy:100,
		for:new Set([0,1,2,3]),
	},
	{
		x:1340,
		y:500,
		dx:255,
		dy:100,
		for:new Set([0,1,2,3]),
	},
]

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._figures=Array.from(this.view.querySelectorAll('.figure')).map((figure,i)=>{
			let state={
				i:i,
				el:figure
			};
			figure.controller=state;
			return state;
		});
		
		this._state={
			status:'idle',
			cta:34,
		};
		
// 		_right_pos.forEach(pos=>{
// 			let el=document.createElement('div');
// 			el.style.background='rgba(255,0,0,0.5)';
// 			el.style.position='absolute';
// 			el.style.top=(pos.y-pos.dy+95)+'px';
// 			el.style.left=(pos.x-pos.dx+85)+'px';
// 			el.style.width=(2*pos.dx)+'px';
// 			el.style.height=(2*pos.dy)+'px';
// 			this.view.appendChild(el);
// 		});
		
		this._frame=()=>{
			if(this._state.stop) return;
			
			let d=Date.now()-this._state.latest;
			this._state.latest=Date.now();
			this._figures.forEach(state=>{
				if(state.animate){
					state.pos.x=state.pos.x+(state.animate.x-state.pos.x)/4;
					state.pos.y=state.pos.y+(state.animate.y-state.pos.y)/4;
					
					if(Math.abs(state.pos.x-state.animate.x)<1 && Math.abs(state.pos.y-state.animate.y)<1){
						state.pos.x=state.animate.x;
						state.pos.y=state.animate.y;
						
						delete state.animate;
					}
				}
			});
			this._render();
			
			if(this._state.status!=="") window.requestAnimationFrame(()=>this._frame());
		}
		
		this._pointer_down=e=>{
			if(this._state.status!=='idle') return;
			let figure=psl_ui.DOM.controller(e.target);
			if(!figure || figure.constructor!==Object) return;
			if(!figure.draggable) return;
			
			this._state.current_figure = figure;
			this._state.mouse_pos_start={
				x:e.clientX,
				y:e.clientY
			};
			figure.el.style.zIndex=zindex++;
			this._state.status="dnd";
			this._rendered.rpoints.label.classList.add('hidden');
			this._rendered.rpoints.labels[figure.i].classList.add('hidden');
		}
		
		this._pointer_move=e=>{
			if(this._state.status!=='dnd') return;
			let figure=this._state.current_figure;
			figure.pos.x=figure.initial_pos.x-(this._state.mouse_pos_start.x-e.clientX)/psl_pages.scale;
			figure.pos.y=figure.initial_pos.y-(this._state.mouse_pos_start.y-e.clientY)/psl_pages.scale;
			
			this._render();
		}
		
		this._pointer_up=async e=>{
			if(this._state.status!=='dnd') return;
			
			let figure=this._state.current_figure;
			if(!figure) return;
			
			let right_pos=_right_pos.find(pos=>{
				if(pos.occupied) return false;
				if(!pos.for.has(figure.i)) return false;
				if(Math.abs(pos.x-figure.pos.x)>pos.dx) return false;
				if(Math.abs(pos.y-figure.pos.y)>pos.dy) return false;
				
				return true;
			});
			
			if(right_pos){
				right_pos.occupied=true;
				figure.draggable=false;
				figure.animate={...right_pos};
				state.score+=50;
				this._state.cta+=8.5;
				audio.play("game-ball-tap-2073");
			} else {
				figure.animate={...figure.initial_pos};
				this._rendered.rpoints.labels[figure.i].classList.remove('hidden');
			}
			
			this._render();
			
			this._state.current_figure=undefined;
			this._state.status='idle';
			
			if(_right_pos.filter(pos=>pos.occupied).length===_right_pos.length){
				this._rendered.rpoints.popup.classList.add('hidden');
				await wait(500);
				this._rendered.rpoints.continue.classList.remove('hidden');
			}
		}
		
		this._reinit();
	}
	
	_reinit(){
		this._figures.forEach(figure=>{
			figure.initial_pos={..._figure_pos[figure.i]};
			figure.pos={..._figure_pos[figure.i]};
			figure.draggable=true;
			this._rendered.rpoints.labels[figure.i].classList.remove('hidden');
		});
		_right_pos.forEach(pos=>pos.occupied=false);
		
		this._state.cta=34;
		
		this._rendered.rpoints.popup.classList.remove('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');
		this._rendered.rpoints.label.classList.remove('hidden');
	}
	
	async show(){
		this._render();
		await super.show();
		
		this.view.addEventListener('pointerdown',this._pointer_down);
		this.view.addEventListener('pointermove',this._pointer_move);
		this.view.addEventListener('pointerup',this._pointer_up);
		
		this._state.stop=false;
		this._state.status="idle";
		this._state.latest=Date.now();
		this._frame();
	}
	async push(){
		this._render();
		await super.show();
		
		this._state.stop=false;
		this._state.status="idle";
		this._state.latest=Date.now();
		this._frame();
	}
	async hide(){
		await super.hide();
		
		this._state.status="";
		
		this.view.removeEventListener('pointerdown',this._pointer_down);
		this.view.removeEventListener('pointermove',this._pointer_move);
		this.view.removeEventListener('pointerup',this._pointer_up);
		
		this._state.stop=true;
		this._reinit();
	}
	
	_render(){
		this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
		this._figures.forEach(figure=>{
			figure.el.style.top=`${figure.pos.y}px`;
			figure.el.style.left=`${figure.pos.x}px`;
		});
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
