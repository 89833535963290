const KIOSK={
	mode:"KIOSK",
	initial_page: "/page_1",
	sound_enabled: false,
	score_enabled: true,
	links_enabled: false,
	page_5_max:false,
};

const TRAILER_1={
	mode:"TRAILER_1",
	initial_page: "/page_1",
	sound_enabled: false,
	score_enabled: false,
	links_enabled: false,
	page_5_max:true,
}

const TRAILER_2={
	mode:"TRAILER_2",
	initial_page: "/page_6",
	sound_enabled: false,
	score_enabled: true,
	links_enabled: false,
	page_5_max:false,
}

const WEB={
	mode:"WEB",
	initial_page: "/page_1",
	sound_enabled: true,
	score_enabled: true,
	links_enabled: true,
	page_5_max:false,
}

let current = WEB;

let mode;

try{
	mode=window.location.search.substr(1).split('&').map(str=>({[str.split('=')[0]]:str.split('=')[1]})).find(el=>el.mode).mode;
} catch(e){
	try {
		mode=window.mode;
	} catch(e){
		
	}
}

switch(mode){
	case "KIOSK":
		current = KIOSK;
	break;
	case "TRAILER_1":
		current = TRAILER_1;
	break;
	case "TRAILER_2":
		current = TRAILER_2;
	break;
	case "WEB":
	default:
		current = WEB;
	break;
}

export default current;
