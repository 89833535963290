import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import {wait} from "../../libs/psl_polyfills.js";

import state from "../../state.js";

import audio from "../../components/audio/component.js";

import './style.scss';
import template from './page.pst';

const PAGE_UID='page_2';

psl_ui.templates.add(PAGE_UID,template);

const _word_pos=[
	{
		y: 782,
		x: 145,
	},
	{
		y: 782,
		x: 470,
	},
	{
		y: 782,
		x: 790,
	},
	{
		y: 924,
		x: 93,
	},
	{
		y: 924,
		x: 420,
	},
	{
		y: 924,
		x: 740,
	}
];

let zindex=1;

const _right_pos=[
	{
		x:584,
		y:482,
		for:new Set([1,4]),
	},
	{
		x:941,
		y:482,
		for:new Set([1,4])
	},
	{
		x:1439,
		y:482,
		for:new Set([3])
	},
];

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._words=Array.from(this.view.querySelectorAll('.word')).map((word,i)=>{
			let state={
				i:i,
				el:word
			};
			word.controller=state;
			return state;
		});
		
		this._state={
			status:'idle'
		};
		
		this._frame=()=>{
			let d=Date.now()-this._state.last;
			this._state.last=Date.now();
			this._words.forEach(state=>{
				if(state.animate){
					state.pos.x=state.pos.x+(state.animate.x-state.pos.x)/4;
					state.pos.y=state.pos.y+(state.animate.y-state.pos.y)/4;
					
					if(Math.abs(state.pos.x-state.animate.x)<1 && Math.abs(state.pos.y-state.animate.y)<1){
						state.pos.x=state.animate.x;
						state.pos.y=state.animate.y;
						
						delete state.animate;
					}
				}
			});
			this._render();
			
			if(this._state.status!=="") window.requestAnimationFrame(()=>this._frame());
		}
		
		this._pointer_down=e=>{
			if(this._state.status!=='idle') return;
			let word=psl_ui.DOM.controller(e.target);
			if(!word || word.constructor!==Object) return;
			if(!word.draggable) return;
			
			this._state.current_word = word;
			this._state.mouse_pos_start={
				x:e.clientX,
				y:e.clientY
			};
			word.el.style.zIndex=zindex++;
			this._state.status="dnd";
		}
		
		this._pointer_move=e=>{
			if(this._state.status!=='dnd') return;
			let word=this._state.current_word;
			word.pos.x=word.initial_pos.x-(this._state.mouse_pos_start.x-e.clientX)/psl_pages.scale;
			word.pos.y=word.initial_pos.y-(this._state.mouse_pos_start.y-e.clientY)/psl_pages.scale;
			
			this._render();
		}
		
		this._pointer_up=async e=>{
			if(this._state.status!=='dnd') return;
			
			let word=this._state.current_word;
			if(!word) return;
			
			let right_pos=_right_pos.find(pos=>{
				if(pos.occupied) return false;
				if(!pos.for.has(word.i)) return false;
				if(Math.abs(pos.x-word.pos.x)>110) return false;
				if(Math.abs(pos.y-word.pos.y)>100) return false;
				
				return true;
			});
			
			if(right_pos){
				right_pos.occupied=true;
				word.draggable=false;
				word.animate={...right_pos};
				state.score+=50;
			} else {
				word.animate={...word.initial_pos};
			}
			
			this._render();
			
			this._state.current_word=undefined;
			this._state.status='idle';
			
			if(_right_pos.filter(pos=>pos.occupied).length===_right_pos.length){
				audio.play("unlock-game-notification-253");
				this._rendered.rpoints.popup.classList.add('hidden');
				await wait(500);
				this._rendered.rpoints.continue.classList.remove('hidden');
			} else if(right_pos){
				audio.play("game-ball-tap-2073");
			} else {
				audio.play("click-error-1110");
			}
		}
		
		this._reinit();
	}
	
	_reinit(){
		this._words.forEach(word=>{
			word.initial_pos={..._word_pos[word.i]};
			word.pos={..._word_pos[word.i]};
			word.draggable=true;
		});
		_right_pos.forEach(pos=>pos.occupied=false);
		
		this._rendered.rpoints.popup.classList.remove('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');
	}
	
	async show(){
		this._render();
		await super.show();
		
		this.view.addEventListener('pointerdown',this._pointer_down);
		this.view.addEventListener('pointermove',this._pointer_move);
		this.view.addEventListener('pointerup',this._pointer_up);
		
		this._state.status="idle";
		this._state.last=Date.now();
		this._frame();
	}
	async push(){
		this._render();
		await super.show();
	}
	async hide(){
		await super.hide();
		
		this._state.status="";
		
		this.view.removeEventListener('pointerdown',this._pointer_down);
		this.view.removeEventListener('pointermove',this._pointer_move);
		this.view.removeEventListener('pointerup',this._pointer_up);
		
		this._reinit();
	}
	
	_render(){
		this._words.forEach(word=>{
			word.el.style.top=`${word.pos.y}px`;
			word.el.style.left=`${word.pos.x}px`;
		});
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
