import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import {wait} from "../../libs/psl_polyfills.js";

import psl_actions from "../../libs/psl_actions.js";

import './style.scss';
import template from './page.pst';

import state from "../../state.js";

import audio from "../../components/audio/component.js";

const PAGE_UID='page_13';

psl_ui.templates.add(PAGE_UID,template);

const _positions=[
	{
		img:'/img/page_13/scotch-broom.png',
		x:532,
		y:272,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:1670,
		y:350,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:1370,
		y:338,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:1168,
		y:648,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:1465,
		y:509,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:994,
		y:129,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:1184,
		y:317,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:439,
		y:450,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:722,
		y:398,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:648,
		y:847,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:856,
		y:751,
		angle:0
	},
	{
		img:'/img/page_13/scotch-broom.png',
		x:1019,
		y:505,
		angle:0
	},
	
	{
		img:'/img/page_13/babys-breath-halfpx-outline-2.png',
		x:1358,
		y:700,
		angle:0
	},
	{
		img:'/img/page_13/babys-breath-halfpx-outline-2.png',
		x:1252,
		y:555,
		angle:0
	},
	{
		img:'/img/page_13/babys-breath-halfpx-outline-2.png',
		x:416,
		y:885,
		angle:0
	},
	{
		img:'/img/page_13/babys-breath-halfpx-outline-2.png',
		x:843,
		y:932,
		angle:0
	},
	{
		img:'/img/page_13/babys-breath-halfpx-outline-2.png',
		x:723,
		y:610,
		angle:0
	},
	{
		img:'/img/page_13/babys-breath-halfpx-outline-2.png',
		x:1691,
		y:538,
		angle:0
	},
	{
		img:'/img/page_13/babys-breath-halfpx-outline-2.png',
		x:1658,
		y:691,
		angle:0
	},
	{
		img:'/img/page_13/babys-breath-halfpx-outline-2.png',
		x:371,
		y:715,
		angle:0
	},
	{
		img:'/img/page_13/babys-breath-halfpx-outline-2.png',
		x:724,
		y:185,
		angle:0
	},
	{
		img:'/img/page_13/babys-breath-halfpx-outline-2.png',
		x:878,
		y:380,
		angle:0
	},
];

const _ignore=new Set([4,6,16,18])

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._particles=_positions.map(particle=>{
			particle.el=document.createElement("img");
			particle.el.src=particle.img;
			particle.el.className="species";

			//if(particle.angle===0) particle.angle=-30+60*Math.random();
			
			particle.scale=0;
			particle.opacity=0;
			particle.status="hidden";
			
			this._render_particle(particle,0);

			particle.el.dataset.action=`${PAGE_UID}.click`;
			
			particle.el.controller=particle;
			
			this.view.appendChild(particle.el);
			
			return particle;
		});
		
		this._state={
			countdown:20000,
			cta:0,
		}
		
		this._frame=async ()=>{
			if(this._state.stop) return;
			
			let d=Date.now()-this._state.latest;
			this._state.latest=Date.now();
			this._particles.forEach((particle,i)=>{
				this._render_particle(particle,d);
			});
			this._state.countdown-=d;
			
			this._state.next-=d;
			
			
			if(this._state.next<0){
				let free=this._particles.filter(particle=>particle.status==="hidden");
				if(free.length) free[Math.floor(free.length*Math.random())].status="growing";
				this._state.next=0.75*(1+1*Math.random())*1000;
				
				if(this._state.countdown<3500){
					this._state.next=4000;
				}
			}
			
			if(this._state.countdown>0){
				window.requestAnimationFrame(()=>this._frame());
			} else {
				audio.play("unlock-game-notification-253");
				this._state.cta=34;
				this._state.countdown=0;
				this._rendered.rpoints.popup.classList.add('hidden');
				await wait(500);
				this._rendered.rpoints.continue.classList.remove('hidden');
			}
			
			this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
			
			this._rendered.rpoints.timer.innerHTML=`0:${this._state.countdown<10000?"0":""}${Math.floor(this._state.countdown/1000)}`;
		}
		
		this._on_click=async e=>{
			if(this._state.countdown<=0) return;
			let position=psl_ui.DOM.controller(e.target);
			if(!position) return;
			audio.play("game-ball-tap-2073");
			switch(position.status){
				case 'growing':
					position.status='disappearing';
					state.score+=50;
					this._state.cta=Math.min(34,this._state.cta+7);
				case 'idling':
					position.status='disappearing';
					state.score+=20;
					this._state.cta=Math.min(34,this._state.cta+3.5);
				break;
			}
		}
		
		this._reinit();
	}
	_reinit(){
		this._particles.forEach((particle,i)=>{
			if(_ignore.has(i)){
				particle.opacity=1;
				particle.scale=1;
				particle.status="idling";
			} else {
				particle.scale=0;
				particle.opacity=0;
				particle.status="hidden";
			}
			this._render_particle(particle,0);
		});
		this._state.countdown=20000;
		this._state.next=0.75*(1+1*Math.random())*1000;
		this._state.latest=Date.now();
		
		this._state.cta=0;
			
		this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
		
		this._rendered.rpoints.popup.classList.remove('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');
	}
	
	async show(){
		await super.show();
		
		psl_actions.add(`${PAGE_UID}.click`,this._on_click);
		
		this._state.stop=false;
		this._state.latest=Date.now();
		this._frame();
	}
	async push(){
		await super.show();
		
		this._state.stop=false;
		this._state.latest=Date.now();
		this._frame();
	}
	async hide(){
		await super.hide();
		
		psl_actions.remove(`${PAGE_UID}.click`,this._on_click);
		this._state.stop=true;
		this._reinit();
	}
	
	_render_particle(particle,d){
		
		switch(particle.status){
			case "hidden":
				particle.opacity=0;
			break;
			case "growing":
				particle.opacity=1;
				particle.scale+=1.5*1*d/1000;
				if(particle.scale>1){
					particle.scale=1;
					particle.status="idling";
				}
			break;
			case "idling":
				particle.opacity=1;
			break;
			case "disappearing":
				particle.opacity-=3*d/1000;
				particle.scale+=2*d/1000;
				if(particle.opacity<0){
					particle.scale=0;
					particle.status="hidden";
				}
			break;
		}
		
		particle.el.style.transform=`translate3d(${particle.x}px,${particle.y}px,0px) rotate(${particle.angle}deg) scale3d(${particle.scale},${particle.scale},1)`;
		particle.el.style.opacity=particle.opacity;
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
