import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import './style.scss';
import template from './page.pst';

const PAGE_UID='page_11';

psl_ui.templates.add(PAGE_UID,template);

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
	}
	async show(){
		await super.show();
	}
	async push(){
		await super.show();
	}
	async hide(){
		await super.hide();
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
