import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import psl_actions from "../../libs/psl_actions.js";

import {wait} from "../../libs/psl_polyfills.js";

import './style.scss';
import template from './page.pst';

import state from "../../state.js";

import audio from "../../components/audio/component.js";

const PAGE_UID='page_29';

psl_ui.templates.add(PAGE_UID,template);

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));

		this._state={
			started:false,
			cta:0,
		}
		
		this._on_click=async e=>{
			if(!this._state.started) return;
			let el=e.target;
			el.classList.add('hidden');
			
			state.score+=50;
			
			this._state.cta+=10.5;
			
			this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
			
			let invasive=Array.from(this.view.querySelectorAll('.water'));

			if(invasive.length === invasive.filter(el=>el.classList.contains('hidden')).length){
				audio.play("unlock-game-notification-253");
				this._rendered.rpoints.popup.classList.add('hidden');
				await wait(500);
				this._rendered.rpoints.continue.classList.remove('hidden');
			} else {
				audio.play("game-ball-tap-2073");
			}
		}
		
		this._on_begin=async ()=>{
			audio.play("select-click-1109");
			this._rendered.rpoints.note.classList.add('hidden');
			this._rendered.rpoints.begin.classList.add('hidden');
			await wait(500);
			this._rendered.rpoints.popup.classList.remove('hidden');
			this._state.started=true;
		}
		
		this._reinit();
	}
	_reinit(){
		Array.from(this.view.querySelectorAll('.water')).forEach(el=>el.classList.remove('hidden'));

		this._rendered.rpoints.note.classList.remove('hidden');
		this._rendered.rpoints.begin.classList.remove('hidden');
		this._rendered.rpoints.popup.classList.add('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');

		this._state.started=false;
		
		this._state.cta=0;
		
		this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
	}

	async show(){
		await super.show();

		psl_actions.add(`${PAGE_UID}.click`,this._on_click);
		psl_actions.add(`${PAGE_UID}.begin`,this._on_begin);
	}
	async push(){
		await super.show();
	}
	async hide(){
		await super.hide();

		psl_actions.remove(`${PAGE_UID}.click`,this._on_click);
		psl_actions.remove(`${PAGE_UID}.begin`,this._on_begin);
		
		this._reinit();
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
