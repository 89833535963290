import './scss/style.scss';

import psl_router from './libs/psl_router.js';
import psl_pages from './libs/psl_pages.js';

import psl_actions from "./libs/psl_actions.js";

import {wait} from "./libs/psl_polyfills.js";

import page_1 from './pages/page_1/page.js';
import page_2 from './pages/page_2/page.js';
import page_3 from './pages/page_3/page.js';
import page_4 from './pages/page_4/page.js';
import page_5 from './pages/page_5/page.js';
import page_6 from './pages/page_6/page.js';
import page_7 from './pages/page_7/page.js';
import page_8 from './pages/page_8/page.js';
import page_9 from './pages/page_9/page.js';
import page_10 from './pages/page_10/page.js';
import page_11 from './pages/page_11/page.js';
import page_12 from './pages/page_12/page.js';
import page_13 from './pages/page_13/page.js';
import page_14 from './pages/page_14/page.js';
import page_15 from './pages/page_15/page.js';
import page_16 from './pages/page_16/page.js';
import page_17 from './pages/page_17/page.js';
import page_18 from './pages/page_18/page.js';
import page_19 from './pages/page_19/page.js';
import page_20 from './pages/page_20/page.js';
import page_21 from './pages/page_21/page.js';
import page_22 from './pages/page_22/page.js';
import page_23 from './pages/page_23/page.js';
import page_24 from './pages/page_24/page.js';
import page_25 from './pages/page_25/page.js';
import page_26 from './pages/page_26/page.js';
import page_27 from './pages/page_27/page.js';
import page_28 from './pages/page_28/page.js';
import page_29 from './pages/page_29/page.js';
import page_30 from './pages/page_30/page.js';
import page_31 from './pages/page_31/page.js';

import preloader from "./components/preloader.js";

import "./components/score/component.js";
import "./components/progress/component.js";
import "./components/badges/component.js";
import "./components/inactivity.js";
import audio from "./components/audio/component.js";

import state from "./state.js";

import CONFIG from '../CONFIG.js';

if(CONFIG.mode!=='WEB') document.body.style.cursor="none";

preloader.then(async ()=>{
	psl_router.init();
	await wait(500);
	let preloader_div=document.querySelector("#preloader");
	preloader_div.style.opacity=0;
	await wait(500);
	preloader_div.style.display="none";
	
	
	Array.from(document.querySelectorAll('img')).forEach(img=>{
		img.setAttribute("draggable","false");
	})
});

psl_actions.add("check_final_page",e=>{
	if(state.progress===5){
		psl_router.route("/page_31");
	} else {
		audio.play("select-click-1109");
		psl_router.route("/page_6");
	}
});
