let _resources=[
	"/img/common/audio_off.png",
	"/img/common/audio_on.png",
	"/img/common/badge.png",
	"/img/page_4/american_bullfrog.png",
	"/img/common/arrow_right.png",
	"/img/common/arrow_up.png",
	"/img/page_13/babys-breath-halfpx-outline-2.png",
	"/img/page_9/babys-breath-halfpx-outline.png",
	"/img/page_9/babys-breath-halfpx-outline.png",
	"/img/background-img.png",
	"/img/page_1/bkg.jpg",
	"/img/page_10/bkg.jpg",
	"/img/page_11/bkg.jpg",
	"/img/page_12/bkg.jpg",
	"/img/page_13/bkg.jpg",
	"/img/page_14/bkg.jpg",
	"/img/page_15/bkg.jpg",
	"/img/page_16/bkg.jpg",
	"/img/page_17/bkg.jpg",
	"/img/page_18/bkg.jpg",
	"/img/page_19/bkg.jpg",
	"/img/page_2/bkg.jpg",
	"/img/page_20/bkg.jpg",
	"/img/page_21/bkg.jpg",
	"/img/page_22/bkg.jpg",
	"/img/page_23/bkg.jpg",
	"/img/page_24/bkg.jpg",
	"/img/page_25/bkg.jpg",
	"/img/page_26/bkg.jpg",
	"/img/page_27/bkg.jpg",
	"/img/page_28/bkg.jpg",
	"/img/page_29/bkg.jpg",
	"/img/page_3/bkg.jpg",
	"/img/page_30/bkg.jpg",
	"/img/page_31/bkg.jpg",
	"/img/page_4/bkg.jpg",
	"/img/page_5/bkg.jpg",
	"/img/page_6/bkg.jpg",
	"/img/page_7/bkg.jpg",
	"/img/page_8/bkg.jpg",
	"/img/page_9/bkg.jpg",
	"/img/page_29/boat-water-1.png",
	"/img/page_29/boat-water-2.png",
	"/img/page_29/boat-water-3.png",
	"/img/page_29/boat-water-4.png",
	"/img/page_29/boat-water-5.png",
	"/img/page_29/boat-water-6.png",
	"/img/page_4/bunchberry_dogwood.png",
	"/img/page_9/burdock-alone-1.png",
	"/img/page_9/burdock-alone-1.png",
	"/img/page_9/burdock-plant-small.png",
	"/img/page_9/burdock-plant-small.png",
	"/img/common/button_begin.png",
	"/img/common/button_continue.png",
	"/img/common/button_replay.png",
	"/img/common/checkmark.png",
	"/img/page_26/crayfish-1.png",
	"/img/page_26/crayfish-1.png",
	"/img/page_26/crayfish-2.png",
	"/img/page_26/crayfish-2.png",
	"/img/page_23/cta_container.png",
	"/img/page_1/cta.png",
	"/img/page_13/cta.png",
	"/img/page_17/cta.png",
	"/img/page_23/cta.png",
	"/img/page_26/cta.png",
	"/img/page_27/cta.png",
	"/img/page_29/cta.png",
	"/img/page_9/cta.png",
	"/img/page_14/dnd_0_label.png",
	"/img/page_17/dnd_0_label.png",
	"/img/page_14/dnd_0.png",
	"/img/page_17/dnd_0.png",
	"/img/page_14/dnd_1_label.png",
	"/img/page_17/dnd_1_label.png",
	"/img/page_14/dnd_1.png",
	"/img/page_17/dnd_1.png",
	"/img/page_14/dnd_2_label.png",
	"/img/page_17/dnd_2_label.png",
	"/img/page_14/dnd_2.png",
	"/img/page_17/dnd_2.png",
	"/img/page_14/dnd_3_label.png",
	"/img/page_17/dnd_3_label.png",
	"/img/page_14/dnd_3.png",
	"/img/page_17/dnd_3.png",
	"/img/page_4/douglas_fir.png",
	"/img/page_4/eastern_cottontail.png",
	"/img/page_5/eurasian_watermilfoil.png",
	"/img/page_4/european_rabbit.png",
	"/img/page_4/european_wall_lizard.png",
	"/img/page_23/frog.png",
	"/img/page_5/goldfish.png",
	"/img/page_23/goldfish1.png",
	"/img/page_23/goldfish2.png",
	"/img/common/hand_right.png",
	"/img/common/hand_up.png",
	"/img/page_9/hawkweed-option4.png",
	"/img/page_9/hawkweed-option4.png",
	"/img/page_14/label.png",
	"/img/page_4/label.png",
	"/img/page_23/lizard.png",
	"/img/page_19/log-zoom.png",
	"/img/page_19/magnifier.png",
	"/img/page_26/milfoil-1.png",
	"/img/page_26/milfoil-1.png",
	"/img/page_26/milfoil-2.png",
	"/img/page_26/milfoil-2.png",
	"/img/page_26/milfoil-3.png",
	"/img/page_26/milfoil-3.png",
	"/img/page_26/milfoil-4.png",
	"/img/page_26/milfoil-4.png",
	"/img/page_23/milfoil.png",
	"/img/page_26/mussels-1.png",
	"/img/page_26/mussels-1.png",
	"/img/page_26/mussels-2.png",
	"/img/page_26/mussels-2.png",
	"/img/page_26/mussels-3.png",
	"/img/page_26/mussels-3.png",
	"/img/page_4/native.png",
	"/img/page_5/native.png",
	"/img/page_5/northern_pandweed.png",
	"/img/page_23/note.png",
	"/img/page_29/note.png",
	"/img/page_4/periwinkle.png",
	"/img/page_6/pin_green.png",
	"/img/page_6/pin_red.png",
	"/img/common/popup_claim.png",
	"/img/common/popup_dnd.png",
	"/img/common/popup_touch_to_disappear.png",
	"/img/page_13/popup.png",
	"/img/page_14/popup.png",
	"/img/page_17/popup.png",
	"/img/page_19/popup.png",
	"/img/page_23/popup.png",
	"/img/page_26/popup.png",
	"/img/page_27/popup.png",
	"/img/page_29/popup.png",
	"/img/page_4/popup.png",
	"/img/page_5/popup.png",
	"/img/page_9/popup.png",
	"/img/page_23/rabbit.png",
	"/img/page_5/rainbow_trout.png",
	"/img/page_5/red_eared_slider.png",
	"/img/page_4/scotch_broom.png",
	"/img/page_13/scotch-broom.png",
	"/img/page_1/slider.png",
	"/img/page_4/spongy_moth.png",
	"/img/page_23/turtle.png",
	"/img/page_23/turtle.png",
	"/img/public/UI-button-small.png",
	"/img/public/UI-progress-bar-back.png",
	"/img/public/UI-progress-bar-green.png",
	"/img/public/UI-score.png",
	"/img/page_5/western_painted_turtle.png",
	"/img/page_2/word-1.png",
	"/img/page_3/word-1.png",
	"/img/page_2/word-2.png",
	"/img/page_3/word-2.png",
	"/img/page_2/word-3.png",
	"/img/page_3/word-3.png",
	"/img/page_2/word-4.png",
	"/img/page_3/word-4.png",
	"/img/page_2/word-5.png",
	"/img/page_2/word-6.png",
	"/img/page_5/zebra_mussels.png"
];

function _load(url){
	return new Promise((resolve)=>{
		let img=new Image();
		img.onload=resolve;
		img.src=url;
	});
}

let _ready=new Promise(async (resolve)=>{
	await Promise.all(_resources.map(_load));
	resolve();
});

export default _ready;
