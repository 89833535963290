import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_actions from '../../libs/psl_actions.js';

import template from './template.pst';

import state from "../../state.js";

import CONFIG from "../../../CONFIG.js";

import "./style.scss";

const _volume=0.75;

const _map=new Map(([
	['bonus-earned-in-video-game-2058','/sound/mixkit-bonus-earned-in-video-game-2058.mp3'],
	['click-error-1110','/sound/mixkit-click-error-1110.mp3'],
	['completion-of-a-level-2063','/sound/mixkit-completion-of-a-level-2063.mp3'],
	['game-ball-tap-2073','/sound/mixkit-game-ball-tap-2073.mp3'],
	['select-click-1109','/sound/mixkit-select-click-1109.mp3'],
	['unlock-game-notification-253','/sound/mixkit-unlock-game-notification-253.mp3']
]).map(([uid,src])=>{
	if(!CONFIG.sound_enabled) return [uid,uid];
	const audio=new Audio();
	audio.preload='auto';
	audio.src=src;
	audio.loop=false;
	audio.load();
	if(uid!=='completion-of-a-level-2063') audio.volume=_volume;
	
	return [uid,audio];
}));

psl_ui.templates.add('audio',template);

const {el}=psl_ui.templates.render('audio');

let _status=true;

const _bkg=new Audio();
_bkg.preload='auto';
_bkg.loop=true;
_bkg.load();
_bkg.volume=_volume;

function _render(){
	el.className=`audio ${_status?'on':'off'}${CONFIG.sound_enabled?"":" disabled"}`;
}

_render();

psl_pages.view.appendChild(el);

psl_pages.events.on("show",async uid=>{
	if(!CONFIG.sound_enabled) return;
	let page_number=+uid.substr(5);
	if(page_number===31){
		let _interval=()=>{
			_bkg.volume=_bkg.volume/1.2;
			console.log(_bkg.volume);
			if(_bkg.volume>0.001){
				setTimeout(_interval,16);
			} else {
				_bkg.pause();
				if(_status) _ext.play("completion-of-a-level-2063");
			}
		}
		_interval();
	} else {
		_bkg.volume=_volume;
		let song=page_number<6?"/music/Sneaky-Adventure.mp3":"/music/Fluffing-a-Duck.mp3";
		if(song===_bkg.song) return;
		_bkg.src=_bkg.song=song;
		if(_status){
			try{
				await _bkg.play();
			} catch (e){
				if(e.name==='NotAllowedError'){
					let listener=()=>{
						if(_status) _bkg.play();
						window.removeEventListener("pointerdown",listener);
					}
					window.addEventListener("pointerdown",listener,true);
				}
			}
		} else {
			_bkg.pause();
		}
	}
});

psl_actions.add("audio.toggle",()=>{
	if(!CONFIG.sound_enabled) return;
	_status=!_status;
	if(_status){
		_bkg.play();
	} else {
		_bkg.pause();
	}
	_render();
});


const _ext={
	play:uid=>{
		if(!CONFIG.sound_enabled) return;
		if(!_status) return;
		let audio=_map.get(uid);
		audio.currentTime=0;
		audio.play();
	}
}

export default _ext;
