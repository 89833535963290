import psl_router from '../libs/psl_router.js';

import state from "../state.js";


import CONFIG from "../../CONFIG.js";
let _timer;

function _active(){
	if(_timer) clearTimeout(_timer);
	_timer=setTimeout(()=>{
		_timer=undefined;
		state.reinit();
		psl_router.route(CONFIG.initial_page);
	},1.5*60*1000);
}

_active();

window.addEventListener('pointerdown',_active,true);
window.addEventListener('pointermove',_active,true);
window.addEventListener('pointerup',_active,true);
