import psl_ui from '../../libs/psl_ui.js';
import psl_pages from '../../libs/psl_pages.js';
import psl_router from '../../libs/psl_router.js';

import {wait} from "../../libs/psl_polyfills.js";

import './style.scss';
import template from './page.pst';

import state from "../../state.js";

import audio from "../../components/audio/component.js";

const PAGE_UID='page_19';

psl_ui.templates.add(PAGE_UID,template);

const _range={
	x:[375,1575-375],
	width:2729-283,
	y:[500,725-500],
	height:737-285
};

const _limits={
	x:[305,1650],
	y:[425,800],
}

const _pos=[
	{x:402,y:613},
	{x:736,y:574},
	{x:744,y:699},
	{x:1026,y:510},
	{x:1292,y:516},
	{x:1293,y:695},
	{x:1519,y:511}
];

const d=(p1,p2)=>Math.sqrt(Math.pow(p1.x-p2.x,2)+Math.pow(p1.y-p2.y,2));

class Page extends psl_pages.page{
	constructor(...args){
		super(...args);
		this.view.classList.add(PAGE_UID);
		this._rendered=psl_ui.templates.render(PAGE_UID);
		this._rendered.els.forEach(el=>this.view.appendChild(el));
		
		this._state={
			status:'idle',
			pos:{
				x:742.5,
				y:707.5
			},
			cta:78,
		};
		
		this._pointer_down=e=>{
			this._state.status="dnd";
			this._state.pos={
				x:(e.clientX-Math.max(0,(window.innerWidth-1920*psl_pages.scale)/2))/psl_pages.scale,
				y:(e.clientY-Math.max(0,(window.innerHeight-1080*psl_pages.scale)/2))/psl_pages.scale
			};
			
			this._render();
			
			let sound;
			
			_pos.forEach(pos=>{
				if(pos.seen) return;
				if(d(this._state.pos,pos)<75){
					pos.seen=true;
					state.score+=20;
					this._state.cta+=13;
					sound="game-ball-tap-2073";
				}
			});
			
			if(sound&&_pos.filter(_pos=>(!_pos.seen)).length===0) sound="unlock-game-notification-253";
			
			if(sound) audio.play(sound);
			
			this._rendered.rpoints.popup.classList.add('hidden');
			this._rendered.rpoints.continue.classList.remove('hidden');
		}
		
		this._pointer_move=e=>{
			if(this._state.status!=='dnd') return;
			this._state.pos={
				x:(e.clientX-Math.max(0,(window.innerWidth-1920*psl_pages.scale)/2))/psl_pages.scale,
				y:(e.clientY-Math.max(0,(window.innerHeight-1080*psl_pages.scale)/2))/psl_pages.scale
			};
			
			let sound;

			_pos.forEach(pos=>{
				if(pos.seen) return;
				if(d(this._state.pos,pos)<75){
					pos.seen=true;
					state.score+=20;
					this._state.cta+=13;
					sound="game-ball-tap-2073";
				}
			});
			
			if(sound&&_pos.filter(_pos=>(!_pos.seen)).length===0) sound="unlock-game-notification-253";
			
			if(sound) audio.play(sound);
			
			this._render();
		}
		
		this._pointer_up=async e=>{
			this._state.status="idle";
		}
		
		this._reinit();
	}
	
	_reinit(){
		this._rendered.rpoints.popup.classList.remove('hidden');
		this._rendered.rpoints.continue.classList.add('hidden');
		
		_pos.forEach(pos=>pos.seen=false);
		
		this._state.cta=78;
		
		this._render();
	}
	
	async show(){
		this._render();
		await super.show();
		
		this.view.addEventListener('pointerdown',this._pointer_down);
		this.view.addEventListener('pointermove',this._pointer_move);
		this.view.addEventListener('pointerup',this._pointer_up);
		
		this._state.status="idle";
	}
	async push(){
		this._render();
		await super.show();
	}
	async hide(){
		await super.hide();
		
		this._state.status="idle";
		
		this.view.removeEventListener('pointerdown',this._pointer_down);
		this.view.removeEventListener('pointermove',this._pointer_move);
		this.view.removeEventListener('pointerup',this._pointer_up);
		
		this._reinit();
	}
	
	_render(){
		if(this._state.pos.x<_limits.x[0]||this._state.pos.x>_limits.x[1]||this._state.pos.y<_limits.y[0]||this._state.pos.y>_limits.y[1]){
			this._rendered.rpoints.container.style.display='none';
		} else {
			this._rendered.rpoints.container.style.display='';
			this._rendered.rpoints.container.style.left=this._state.pos.x+'px';
			this._rendered.rpoints.container.style.top=this._state.pos.y+'px';
		}
		this._rendered.rpoints.cta.style.height=`${this._state.cta}px`;
		
		this._rendered.rpoints.zoom.style.transform=`translate3d(${-(_range.width*(this._state.pos.x-_range.x[0])/_range.x[1])}px,${-(_range.height*(this._state.pos.y-_range.y[0])/_range.y[1])}px,0px)`;
		
		this._rendered.rpoints.counter.innerHTML=`${_pos.filter(pos=>pos.seen).length}/${_pos.length}`
	}
}

const _page=new Page();

psl_pages.add(PAGE_UID,_page);

psl_router.add_route(`/${PAGE_UID}`,_page);

export default _page;
